import menu from './menu'
import auth from './auth'
import user from './user'
import film from './film'
import song from './song'
import tradeDetails from './tradeDetails'
import dynamicAnalysis from './dynamicAnalysis'
import option from './option'
import presign from './presign'
import wallet from './wallet'
import order from './order'
import login from './login'
import compensate from './compensate'
import music from './music'
import contract from './contract'
import newsletter from './newsletter'
import signals from './signals'

export default {
  menu,
  auth,
  user,
  film,
  song,
  tradeDetails,
  dynamicAnalysis,
  option,
  presign,
  wallet,
  order,
  login,
  compensate,
  music,
  contract,
  newsletter,
  signals
}
