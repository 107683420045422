export default {
  name: 'Real Name',
  address: 'Address',
  id_number: 'ID Number',
  vat_number: 'Tax ID Number',
  // address: 'Permanent Address',
  registered_address: 'Registered Address',
  mailing_address: 'Mailing Address',
  phone: 'Phone Number',
  company_name: 'Company Name',
  owner: 'Legal Representative',
  cancel: 'Cancel',
  confirm: 'Confirm',
  checkout: 'Checkout',
  songWallet: 'My Sold Tracks',
  wallet: 'Wallet',
  purchased: 'Transaction Detail',
  plzChoice: 'Please choose...',
  required: ' is required',
  musicNotice:
    'Please enter the price of a commercial video for one country, other would refer to the rates of ',
  scaleTable: 'reference price table',
  paymentHeader: 'Fill in payment information',
  walletDetailHeader: {
    __tradingDay: 'Date',
    __buySongs: 'Song Name',
    __priceAmount: 'Listed Price',
    __customerName: 'Clients',
    __note: 'Notes',
    __positive: 'Song Price',
    __negative: 'Withdrawal'
  },
  orderDetailHeader: {
    __tradingDay: 'Date',
    __transactionItem: 'Items',
    __depositWithdraw: 'Paid',
    __downloadFile: 'Download',
    __note: 'Note',
    __member: 'Membership',
    __memberCancel: 'Unsubscribe',
    __song: 'Purchased Music',
    __coupon: 'Tickets',
    __record: 'Mixed Video/Audio'
  },
  videoErrorType: 'The upload format is {type}, Please input MP4 format video',
  imageErrorType: 'The upload format is {type}, Please input JPEG or PNG format photo',
  imageErrorType2: 'The upload format unacceptable, Please input JPEG format photo',
  fileErrorType: 'The upload format unacceptable, Please input .docx, .txt, .csv, .xls, .html, .md, .pptx, .pdf, .jpg, .png format file',
  contractCheckBox: 'I have read and agree to the contract',
  Copyright: 'Copyright License Agreement',
  personal: 'Personal',
  enterprise: 'Enterprise',
  roles: 'Roles',
  allItem: 'All',
  transferIn: 'Transfer In',
  transferOut: 'Transfer Out',
  system: {
    alert: {
      __confirm: 'Confirm'
    },
    input: {
      placeholder: 'Please input...',
      required: 'Is required.'
    },
    autocomplete: {
      placeholder: 'Please choose...'
    },
    __login: 'Please login first.',
    __disableMobile: 'The mobile version of the service is being developed, stay tuned.'
  },
  all: {
    userIdentity: {
      __chooseYourIdentity: 'Choose Your Role',
      __musician: 'Musician',
      __photographer: 'Filmmakers',
      __confirm: 'Confirm'
    },
    navList: {
      __aboutUs: 'About Us',
      aboutUs: {
        __home: 'Sync',
        __creators: 'Creators',
        __dearMusician: 'Dear Musician',
        __membership: 'Membership'
      },
      __filmMusicMapping: 'Film Music Mapping',
      user: {
        __film: 'Film Management',
        __music: 'Musicians Playground',
        // __profile: 'Profile',
        // __chooseYourIdentity: 'Choose Your Role',
        __signOut: 'Sign Out'
      },
      faq: {
        __title: 'User Guide',
        __path: 'https://avmapping.co/en/blog/video-music-mapping-user-guide/'
      },
      __upgrade: 'Upgrade to Pro',
      currency: {
        __twd: 'TWD',
        __usd: 'USD',
        __eur: 'EUR'
      }
    },
    mobile: {
      menu: {
        __aboutUs: 'About Us',
        __home: 'Sync',
        __creators: 'Creators',
        __dearMusician: 'Dear Musician',
        __membership: 'Membership',
        __filmMusicMapping: 'Film Music Mapping',
        __film: 'Film Management',
        __music: 'Musicians Playground'
      },
      __chooseYourIdentity: 'Choose Your Role',
      __signOut: 'Sign Out'
    }
  },
  filmMusicMapping: {
    step1: {
      __title: 'Video Music Analysis',
      __uploadFilm: 'Upload your video (MP4 format, under 50MB)',
      __errorYoutube: 'Please check if the YouTube link is correct.',
      __errorNoMusic: 'Please larger your budget range or conditions because we don’t have recommendations in your conditions.',
      __alertMappingMsg: 'Please hold on... You will receive a notification when the analysis is completed.',
      __alertMappingNote: 'Please note:\n1. If you want to proceed with another video analysis, please open a new tab.\n2. Once you leave this page, the analysis result will go directly to "My Video Library" page instead of "Step 2 - Editing".',
      __finishMappingMsg: 'We get your video ready. Check it out!',
      __confirmButton1: 'New Analysis',
      __confirmButton2: 'Close',
      buttonGroup: {
        __confirm: 'Confirm',
        __cancel: 'Cancel',
        title: {
          __title: 'Title'
        },
        story: {
          __title: 'Plot Summary',
          __note: 'Plot Summary'
        },
        referenceMusic: {
          __title: 'Reference Music',
          __note: '- Please Enter YouTube URL',
          __alert: 'Please Enter YouTube URL'
        },
        budgetRange: {
          __title: 'Budget',
          __placeholder: 'Please Enter Amount',
          __min: 'Minimum  ≧ 1',
          __max: 'Maximum ≦ 70M',
          __validateError:
            'Limited Upper Price under 70M USD, please enter again.',
          __currency: '(USD)',
          __hint: '💡Hint: Raise up the budget to have better music since our music from professional musicians.'
        },
        licenseUsage: {
          __title: 'Usage',
          __commercial: 'Commercial',
          __noncommercial: 'Non Commercial'
        },
        licenseArea: {
          __title: 'Authorized Area',
          __region_type: 'Authorized Region Type',
          __region: 'Authorized Region',
          __country: 'Authorized Country'
        },
        licenseTime: {
          __title: 'Authorized Time'
        },
        spec: {
          __title: 'Special Needs',
          __instrument: 'Instrument',
          __genre: 'Genre',
          __emotion: 'Emotion',
          speed: {
            __title: 'Speed',
            __note: 'Please enter BPM (20-220)'
          },
          other: {
            __title: 'Other',
            __note: 'Please enter other special requirements'
          }
        }
      },
      startAnalysis: {
        __submitNotice: 'Press Start would count one time.',
        __start: 'Start',
        __alert1: 'Please upload your film in MP4 format video',
        __alert2:
          'Required items (usage, authorized area, authorized time) are not filled',
        __uploading: 'Video uploading, please wait...<br>',
        __uploadFinish: 'Upload completed! Analyzing...',
        __analyzing: 'Analyzing...',
        __budgetError: 'Budget upper bound must be greater than lower bound.',
        __noiseError: 'The time points you requested are more than actual video length, please check again.',
        __noiseEndError: 'Noise range end time must lower or equal film duration.',
        __effectError: 'The time points you requested are more than actual video length, please check again.'
      },
      lack: {
        __slogan: 'We are sorry...',
        __message: `Currently we don’t have music meeting your request. We apologize for that and we would keep broadening our music library to serve you.<br>
        <br>
        Wanna recommend your favorite music?<br>
        Share to the musician and help them get high rebate!<br>
        <br>
        Thank you so much for visiting A.V. Mapping.<br>
        See you soon !`,
        __share: 'Share',
        __shareMessage: `【Dear Friend】 I recommend you to upload your music into A.V. Mapping, the most professional platform, would help you to become part of video music projects automatically and also share high profit with you. Let's try and share 👉${process.env.VUE_APP_HOME_URL}{locale}/?recommend={id}#register`,
        __copyMessage: 'The link has been copied to clipboard.',
        __coupon: 'Extra coupon',
        __couponUrl: 'https://www.surveycake.com/s/aZXVN',
        __analysis: 'Start again',
        __close: 'Close'
      }
    },
    step2: {
      __title: 'Video Music Analysis',
      __alert: 'Heads up- Sharing recordings without license violates the Copyrights, Community Guidelines and will result in suspension. Thx!',
      songBlock: {
        keyword: {
          __title: 'Keywords'
        },
        songs: {
          __title: 'Songs',
          check: {
            __note: 'Check to confirm purchase',
            __checked: 'Checked'
          }
        }
      },
      result: {
        __confirm: 'Confirm purchase',
        __reanalyse: 'Reanalyse'
      },
      alert: {
        __confirm: 'Confirm',
        __msg: 'Please choose at least one song',
        __noise: 'This video has no sound so we didn’t reduced the noise.'
      },
      adjust: {
        __likeMusic: 'Do you like any recommended music?',
        __unlike: 'None',
        __request: 'We can adjust your requirements.'
      }
    }
  },
  filmMusicMappingV2: {
    step1: {
      __title: 'Choose the service',
      __cancel: 'Cancel',
      __complete: 'Done',
      __alert: 'Please enter YouTube link',
      __placeholder: 'Reference music YouTube link',
      __limit: 'Upload your video (MP4 format, under 50MB)',
      __limitForMobile: 'Upload your video (under 50MB)',
      __durationLimit: 'Online streaming video should be under 1 hour. Or contact us for the support.',
      __isLive: 'Live streaming videos are not supported yet. Or contact us for the support.',
      file: {
        __upload: 'Upload your video / photo / audio',
        __uploadWithoutAudio: 'Upload your video / photo',
        __uploadMusic: 'Upload your audio',
        __drag: 'Upload or drag your video / photo / audio',
        __dragVillage: 'Upload or drag your video / photo',
        __youtube: 'Or enter YouTube link as reference to find the similar music'
      },
      service: {
        soundtrack: {
          __title: 'AI Video Music Mapping',
          __content: [
            'Save time to find the best music.',
            'Get music license directly.',
            'We can also help your photos into video!'
          ]
        },
        effect: {
          __title: 'AI SFX',
          __content: [
            'Get AI-edited SFX.'
          ]
        },
        reduction: {
          __title: 'AI Noise Reduction',
          __content: [
            'Remove noise by AI in one click.',
            'No need to spend hours on audio processing.'
          ]
        }
      },
      uploadFilm: {
        __uploading: 'Video uploading, please wait...<br>',
        __uploadFinish: 'Upload completed!'
      },
      alert: {
        __wait: `Nice to meet you here!
        Unfortunately, we have so many videos in queue in this moment.
        We are working on it.
        You can still upload the videos/image, and we will notice you when it finished!
        Or please come back tomorrow.
        (It might take 12 hours but faster than 6 months which should take. 😂)

        See you around! Enjoy your tea time!🫖`
      }
    },
    'step1-2': {
      __alertMappingMsg: 'Please hold on... You will receive a notification when the analysis is completed.',
      __alertMappingNote: 'Please note:\n1. If you want to proceed with another video analysis, please open a new tab.\n2. Once you leave this page, the analysis result will go directly to "My Video Library" page instead of "Step 2 - Editing".',
      __confirmButton1: 'New Analysis',
      __confirmButton2: 'Close',
      __errorNoMusic: 'Please larger your budget range or conditions because we don’t have recommendations in your conditions.',
      __errorYoutube: 'Please check if the YouTube link is correct.',
      __finishMappingMsg: 'We get your video ready. Check it out!',

      __cancel: 'Clear',
      __confirm: 'Confirm',
      __name: 'Name',
      __placeholder: 'Please input file name',
      __selectPlaceholder: 'Unselected',
      __type: 'Type',
      usage: {
        __usage: 'Video Usage',
        __platform: 'Video Platform'
      },
      buttonGroup: {
        algorithm: {
          __title: 'Algorithms'
        },
        budget: {
          __title: 'Budget'
        },
        budgetRange: {
          __hint: '💡Hint: Raise up the budget to have better music since our music from professional musicians.',
          __min: 'Minimum  ≧ 1',
          __max: 'Maximum ≦ 70M'
        },
        licenseArea: {
          __title: 'Location',
          __type: 'Location type',
          __region: 'Authorized Region',
          __country: 'Authorized Country'
        },
        linceseTime: {
          __title: 'Period'
        },
        licenseUsage: {
          __commercial: 'Commercial',
          __noncommercial: 'Non Commercial',
          __title: 'Video Usage'
        },
        mobile: {
          __required: 'Required',
          __optional: 'Optional'
        }
      },
      soundtrack: {
        __head: 'AI Video Music Mapping',
        __title: 'Music',
        __titleV1: 'Other Needs',
        __add: 'Add music',
        __edit: 'Edit Cue Sheet',
        options: {
          __time: 'Insert Time',
          __ref: 'Reference Music',
          __outline: 'Plot Summary',
          __keyword: 'Keyword',
          __other: 'Other',
          __instrument: 'Instrument',
          __genre: 'Genre',
          __mood: 'Mood',
          __bpm: 'BPM',
          __lang: 'Language'
        }
      },
      effect: {
        __head: 'AI SFX',
        __type: 'Sound effects type',
        __title: 'SFX',
        __add: 'Add SFX',
        __edit: 'Edit Cue Sheet',
        options: {
          __time: 'Insert Time',
          __object: 'Insert Object',
          __action: 'Insert Action',
          __unfilled: 'Unfilled'
        }
      },
      reduction: {
        __head: 'AI Noise Reduction',
        __capture: 'Noise Captured Timing',
        __noise: 'Noise Reduction',
        __add: 'Add more timing'
      },
      startAnalysis: {
        __analyzing: 'Analyzing...',
        __start: 'Start',

        error: {
          __budget: 'Budget upper bound must be greater than lower bound.',
          __effect: 'The time points you requested are more than actual video length, please check again.',
          __noise: 'The time points you requested are more than actual video length, please check again.',
          __noiseEnd: 'Noise range end time must lower or equal film duration.',
          __notAvailable: 'The algorithm is not available for this file.',
          __refRequired: 'The reference music is required for the algorithm Mosaic on the video type film.'
        }
      },
      analysis: {
        __start: 'Start',
        __reanalyze: 'Reanalyze',
        __cancel: 'Cancel'
      },
      algorithm: {
        __whatever: ': just give me the best',
        __sonnet: ': Emphasis on emotional and lexical analysis',
        __sonata: ': Focus on image analysis and integrated music classification analysis',
        __mosaic: ': Emphasis on similarity analysis with reference to music'
      }
    },
    step2: {
      __soundtrack: 'AI Video Music Mapping(M)',
      __effect: 'AI SFX(S)',
      __reduction: 'AI Noise Reduction(N)',
      __reductionTips: "Your video doesn't include the audio & noise which need to be reduced. ",
      __total: 'Total',
      __currency: 'USD',
      __more: 'More',
      __reanalyze: 'Reanalyze',
      __prev: 'Prev',
      __next: 'Next',
      __checkout: 'Checkout',
      alert: {
        __unconnected: 'There is something wrong with the internet connection. Please change a hotspot and try again later.',
        __single: 'One selection each category, please check again. ',
        __effectLimit: 'Sound effects can only choose up to three songs.'
      },
      order: {
        __title: 'Your shopping list',
        single: {
          __title: 'Single purchase'
        },
        mixer: {
          __add: 'Add New Multiple Choices Mixing',
          __content: 'Select items to include in the multiple-choices-mixing.',
          __title: 'Multiple Choices Mixing',
          // __content: 'Select items to include in the multi-purchase. After confirmation, click copy to get a link of the sample video with soundtrack.',
          __recordVideo: 'Video Producing',
          __recordAudio: 'Audio Mixing Only'
        }
      },
      share: {
        __title: 'Sharable Preview link',
        __copied: 'Link copied to the clipboard'
      },

      village: {
        __nextButton: 'Just Show Me',
        __nextContent: 'I don’t want to join concert, but I’d like to show in the village.',
        __saveChooseButton: 'Join FAIM Concert',
        __saveChooseContent: 'I want to join FAIM concert to have musicians re-score it.'
      }
    },
    checkout: {
      __count: 'Same soundtrack will not be billed twice',
      __invoice: 'Insert VAT number',
      __cancel: 'Cancel',
      __confirm: 'Payment',
      check: {
        __content: 'I hereby declare that I have read, understood and agree to abide by the Copyright Lincense Agreement.',
        __alert: 'Please check after read the statement.'
      },
      service: {
        __soundtrack: 'AI Video Music Mapping(M)',
        __effect: 'AI SFX(A)',
        __reduction: 'AI Noise Reduction(N)',
        __mixer: 'Multiple Choices Mixing'
      },
      invoice: {
        __global: 'Invoice(Global)',
        __taiwan: 'Receipt(Taiwan)',
        __tip: 'The contract would be signed by your company.'
      },
      promo: {
        __title: 'Promo code',
        __placeholder: 'Insert promo code',
        __exist: 'The promo code you entered does not exist',
        __remove: 'Remove',
        __blank: 'The promo code cannot be blank'
      },
      message: {
        __fail: 'Something went wrong, please try again.',
        __film: 'Something went wrong, please try again.(1)', // 使用者沒有該 film 的權限
        __profile: 'Something went wrong, please try again.(2)', // 指定的 profile 不屬於該使用者
        __missing: 'Something went wrong, please try again.(3)', // 指定的 profile 資料不完整
        __taxID: 'Unavailable tax id.',
        __purchased: 'The item has been purchased.',
        __promo: 'The promo code you entered does not exist.',
        __used: 'The promo code you entered has already been used.',
        __least: 'At least one product to be chosen.'
      },
      tips: {
        __title: 'Add a Tip for AI',
        __sub: 'Tip',
        __content: 'Please select a tip',
        proportion: {
          first: {
            __value: '15',
            __text: 'Not Bad'
          },
          second: {
            __value: '18',
            __text: 'Good'
          },
          third: {
            __value: '20',
            __text: 'Wow!'
          },
          fourth: {
            __value: '25',
            __text: 'Love it!'
          },
          five: {
            __value: '30',
            __text: 'Best Service Ever'
          },
          custom: {
            __value: 'Custom',
            __text: ''
          }
        }
      },
      alert: {
        __content: 'We will delete the multiple choice mixing at the same  time.\nAre you sure to delete here?',
        __confirm: 'Yes, delete it',
        __cancel: 'Keep it'
      }
    },
    download: {
      tab: {
        __soundtrack: 'AI Video Music Mapping(M)',
        __effect: 'AI SFX(S)',
        __reduction: 'AI Noise Reduction(N)',
        __record: 'Multiple Choices Mixing'
      },
      button: {
        __library: 'Back To Library',
        __file: 'Download Music Files',
        __contract: 'Download Contract',
        __record: 'Download Mixed Audio',
        __recordWithVideo: 'Download Mixed Video/Audio'
      }
    },
    search: {
      __premium: 'Premium',
      __search: 'Search',
      __slogan: 'AI Finds Your Music, Quick and Easy.',
      tips: {
        __alert: 'Please enter YouTube link',
        __file: 'Upload or drag your video / photo',
        __keyword: 'Keywords | Scripts | Plot Summary',
        __youtube: 'Enter YouTube link'
      },
      intro: {
        __slogan: 'AI finds your music',
        __title1: 'VIDEO & IMAGES\nTO MUSIC',
        __content1: 'Find the perfect match music through understanding the video & images you uploaded',
        __title2: 'MUSIC TO MUSIC',
        __content2: 'From emotion, genre, musicality to reference music, AI finds the music closes to your preference.',
        __title3: 'TEXT TO MUSIC',
        __content3: 'From text, scripts,\nplot summary, synopsis,\nCUE sheet to music!',
        __button: 'See More'
      },
      contact: {
        __email: 'service@avmapping.co',
        __slogan: 'Contact us'
      }
    }
  },
  user: {
    navList: {
      __personalInformation: 'Personal Information',
      __contract: 'Affiliation & Event contract',
      __myMusicList: 'My Music List',
      __uploadMusic: 'Upload Music',
      __myFilmList: 'My Video Library',
      __myWallet: 'My Wallet',
      __transactionDetail: 'Transaction Detail',
      __signOut: 'Sign Out'
    },
    userInfo: {
      __introduction: 'Introduction',
      __website: 'Website',
      __email: 'Email',
      __country: 'Country',
      __edit: 'Edit Personal Profile',
      __save: 'Save Personal Profile',
      __note:
        '・Click "Edit Personal Profile" to edit. Please click "Save Personal Profile" button after filling in.',
      __editComplete: 'Edit complete',
      __noResult: 'No result',
      more: 'See More',
      less: 'Show Less',
      membership: {
        __title: 'Membership',
        __free: 'Free Trial',
        __promo: 'Promo Trial',
        __business: 'Business',
        __advanced: 'Advanced',
        __personal: 'Personal',
        __nonCommercial: 'Non-Commercial',
        __basic: 'General',
        __note: 'You have {msg} days left, please',
        __noMembership: 'You don\'t have membership, please upgrade your membership.',
        __update: '[Upgrade now👉]'
      },
      message: {
        __success: 'Subscription successfully'
      }
    },
    mySong: {
      __title: 'My Music List',
      songDetail: {
        price: {
          __title: 'Price',
          __currency: 'NTD'
        },
        __artist: 'Artist',
        __bpm: 'BPM',
        __chroma: 'chroma',
        __composer: 'Composer',
        __instrument: 'Instrument',
        __key: 'key',
        __language: 'Language',
        __lyricist: 'Lyricist',
        __mood: 'Mood',
        __musicGenre: 'Music Genre',
        __name: 'Title',
        __op: 'OP(Original Publishing)',
        __price: 'Price (NTD)',
        __singer: 'Singer',
        __sp: 'SP(Sub-Publishing)',
        __tag: 'Tag',
        __title: 'Title',
        __uploadDate: 'Upload Date',

        __releaseTitle: 'Release it on Web 3.0',
        __releaseValue: '(Optional) Also release it on blockchain, I want to track this music in an immutable way. (Pay in advance)',
        __trainingTitle: 'Agree with AI training',
        __trainingValue: '(Optional) I approve my content for AI training.',

        answer: {
          __yes: 'Yes',
          __no: 'No'
        },

        ipfs: {
          __beingProcessed: 'The music is already on IPFS or currently being processed.',
          __dataMissing: 'Please fill the information first',
          __subscription: 'Subscription successful.',
          __subscriptionFailed: 'Subscription failed.',
          __unsubscribe: 'Subscription cancelled.',

          content: {
            __committing: ', uploading content to IPFS.',
            __committed: ', NID number:',
            __canceling: ', Canceling',
            __canceled: ', Canceled, membership expired at',
            __deleting: ', deleting from IPFS.',
            __deleted: ', the data has been deleted.'
          }
        }
      },
      alert: {
        __content: 'Are you sure to delete the song?',
        __note: 'Important: It can not be recovered.',
        __cancel: 'No, Bring me back.',
        __confirm: 'Yes, Please delete.'
      }
    },
    myFilm: {
      __title: 'My Video',
      __incompleteMessage: 'Please hold on... You will receive a notification when the analysis is completed.',
      __inactiveMessage: 'Please start analyzing.',
      message: {
        __1: 'Oh, you didn\'t upload videos this month.',
        __2: 'Just start uploading video/image/text to find music & sound effects from here.',
        __3: 'Or the Reference music\'s YouTube Link to find similar music with licenses',
        __4: 'Don\'t forget to click "Start analyzing"',
        __5: 'After analyzing, you can check the results from this page!',
        __6: 'AVMapping is protecting copyrights carefully. All the video data will be deleted 30 days after uploaded. To keep your data safe and private!'
      },
      filmList: {
        __mappingDate: 'Mapping time',
        __mappingDeadline: 'Consideration period',
        __currency: 'NTD',
        mappingMusicStatus: {
          __toPurchase: 'To Purchase',
          __purchased: 'Purchased'
        }
      },
      filmDetail: {
        __backMyFilm: 'Back to "My Video"',
        __10MappingSong: 'Pick out your top {num} film music',
        __isBought: 'Purchased',
        __reselect: 'Start analyzing',
        __purchase: 'Purchase',
        __step2: 'Editing',
        tips: {
          __like: 'To see the list without likes, press ♥',
          __dislike: 'To see the list with likes, press ♥',
          __purchase: 'Select music name to purchase',
          __mappingLimit: 'Mapping music can only choose up to three songs.'
        }
      }
    },
    uploadSong: {
      __title: 'Upload Music',
      alert: {
        __limit: 'WAV or MP3 only',
        __chooseFile: 'Please choose your file',
        // __songName: 'Please input music name',
        // __artistName: 'Please input artist name.',
        // __price:
        //   'Please enter the price (NTD) when the music license on "international, online advertisement", other prices would refer to our "price-references-list"',
        __contractCheck: 'Please check Platform Agreement'
        // __bpm: 'The BPM field must be 20 or more',
        // __emotion: 'Please input music emotion',
        // __genre: 'Please input music genre',
      },
      fileInput: {
        __upload: 'Choose file or drop file here',
        __tips: '※ Upload format: wav or mp3 only, over 192kbps'
      },
      uploading: {
        __alert:
          "Your file is being uploaded! Please don't close this page until it's finished...",
        __finished: 'Upload Finished',
        __cancel: 'Cancel'
      },
      songData: {
        __name: 'Title',
        __price: 'Price',
        __instrument: 'Instrument',
        __lyricist: 'Lyricist',
        __composer: 'Composer',
        __singer: 'Singer',
        __musicGenre: 'Music Genre',
        __mood: 'Mood',
        __bpm: 'BPM',
        __language: 'Language',
        __sp: 'SP(Sub-Publishing)',
        __op: 'OP(Original Publishing)'
      },
      contract: {
        __title: 'A.V. Mapping platform agreements.',
        __agree:
          'By submitting this application, you: (1) acknowledge that you have reviewed the Contract, Terms and Conditions; and (2) agree to submit your application for this subject to those Terms and Conditions.',
        __alert: 'Please check after read the terms.',
        __content: [
          'I confirmed that the musical composition I registered and managed with A.V. Mapping was declared by fact and no falsification. If a third party claims the rights of such musical composition to A.V. Mapping, I should follow the economic rights agreement about being liable for excluding infringement or compensation in Article 6 signed with A.V. Mapping.',
          'I agreed that the area AV.Mapping managed is stipulated in the economic rights agreement. Only if I have a designated agency area in the composition registration form, A.V. Mapping will manage based on the designated agency area, but my responsibility and obligations under the agreement won’t be influenced.'
        ]
      },
      card: {
        month: {
          __button: 'Per music per month',
          __price: '2 USD',
          __title: 'Monthly Package',
          __value: 'month',
          list: {
            __1: '$ 1.5 per music when over 100 music',
            __2: '$ 1 per music when over 1,000 music',
            __3: 'Under 25MB',
            __4: 'Extra fee if more than 25MB',
            __5: 'AI label and analysis',
            __6: 'Cloud computing and IPFS storage',
            __7: 'DDEX format adjustment',
            __8: 'After cancellation, the data would be unpin, and lose the connection on the chain.',
            __9: 'Including IPFS storage and Web 3.0 gas fee',
            __10: 'Tax and cash platform service fee'
          }
        },
        year: {
          __button: 'Per music per year',
          __price: '20 USD',
          __title: 'Yearly Package',
          __value: 'year',
          list: {
            __1: '$ 15 Over 100 music',
            __2: '$ 12 Over 1,000 music',
            __3: '20% off than monthly package',
            __4: 'AI label and analysis',
            __5: 'Cloud computing and IPFS storage',
            __6: 'DDEX format adjustment',
            __7: 'After cancellation, the data would be unpin, and lose the connection on the chain. ',
            __8: 'Including IPFS storage and Web 3.0 gas fee',
            __9: 'Tax and cash platform service fee'
          }
        }
      },
      __uploadConfirm: 'Confirm'
    },
    myWallet: {
      __title: 'My Wallet',
      info: {
        money: {
          __currentWallet: 'Current Wallet',
          __totalAmount: 'Total Amount',
          __currency: 'NTD'
        },
        mapping: {
          __successMapping: 'Success Mapping',
          __unit: 'films'
        },
        analysis: {
          __count: 'Remaining Analysis Time',
          __unit: 'times this month'
        }
      },
      btn: {
        __transactionDetails: 'Transaction detail',
        __deposit: 'Deposit',
        __withdraw: 'Withdraw',
        __promo: 'Share your voucher',
        __exchange: 'Redeem voucher',
        __success: 'Exchange successful',
        __unsubscribe: 'Unsubscribe'
      },
      __note: [
        'You can check the wallet records in “my transaction details”.',
        'You can withdraw money from us if the unclaimed amount is over 200 USD.',
        'The cash flow is through the NewebPay cash flow system.'
      ],
      __promo: {
        header: 'Wish to get extra vouchers and experience other services?',
        content: [
          'Earn a voucher for you & your friends by recommending us.',
          // eslint-disable-next-line no-template-curly-in-string
          '(Single voucher is worth upto ${amount})'
        ],
        promoCode: 'Your voucher code',
        button: 'Copy code',
        alt: 'Share your voucher'
      },
      __exchange: {
        placeholder: 'Please enter the voucher number',
        button: 'Submit',
        error: "Voucher number doesn't exist"
      },
      __success: {
        // eslint-disable-next-line no-template-curly-in-string
        content: '(EN) 您已經獲得一張價值${amount}元的使用券！',
        alt: '(EN) 兌換成功'
      },
      message: {
        __success: 'Cancelled subscription successfully.',
        __noActive: 'You are not in subscription, you should have one.',
        __beenCancel: 'You don’t have any subscription now.'
      }
    },
    transactionDetail: {
      __title: 'My Transaction Detail',
      query: {
        __transaction: 'Transaction',
        __detailsOfCharge: 'Details of Charge',
        __dateRange: 'Date Range',
        __query: 'Search'
      },
      __note: [
        'Notice',
        '1. You can check your transaction history for the past year here. The longest searching period is 6 months. If you need to access the period longer than 6 months, you’ll need to select each month and view/download them individually.',
        '2. The files for download are only valid for three days. Please download within a limited time.',
        '3. The information on this page cannot be used as a proof of deposit balance or certificate.'
      ],
      result: {
        __back: 'Back to Query',
        download: {
          __contract: 'Contract',
          __music: 'Music',
          __receipt: 'Receipt',
          __video: 'Video',
          __xml: 'Xml'
        },
        table: {
          title: {
            __history: 'Transaction Detail',
            __musicTradeRecords: 'My Sold Tracks',
            __sum: '',
            __no: '',
            __records: 'Records',
            __pages: 'Pages'
          },
          header: {
            musician: {
              __serialNumber: 'SN',
              __tradingTime: 'Trading Time',
              __transactionItem: 'Item',
              __depositWithdraw: 'Deposit / Withdraw',
              __balance: 'Balance',
              __note: 'Note'
            },
            photographer: {
              __serialNumber: 'SN',
              __tradingTime: 'Trading Time',
              __transactionItem: 'Item',
              __depositWithdraw: 'Deposit / Withdraw',
              __balance: 'Balance',
              __note: 'Note'
            }
          }
        }
      }
    },
    uploadContract: {
      __agree: 'By submitting this application, you: (1) acknowledge that you have reviewed the Personal Information, Contract, Terms and Conditions; and (2) agree to submit your application for this subject to those Terms and Conditions.',
      __confirm: 'Confirm',
      __download: 'The Agreement',
      __edit: 'Edit Profile',
      __more: 'Know More',
      __title: 'Affiliation & Event contract',

      current: {
        __film: 'Film Management',
        __music: 'Musicians Playground'
      },

      alert: {
        __check: 'Please check after read the terms.',
        __identity: 'Please choose your Identity',
        __missing: 'Please fill the profile information',
        __success: 'Thanks for signing up “FAIM Fest.” Please keep in tune with us.',
        __upload: 'Please check Platform Agreement'
      }
    }
  },
  errors: {
    __beenUploaded: 'We have this music in our database already, please confirm you upload this one again.',
    __invalid: 'The Youtube url is invalid',
    __login: 'Login error, please go back to the first page to login again.',
    __mixerMissing: 'The mixer record data is missing',
    __unavailable: 'The Youtube url is unavailable',
    __unavailableEffect: 'Unavailable sound effect',
    __unavailableFile: 'Unavailable file',
    __unavailableMusic: 'Unavailable music',
    __unavailableMusicProfile: 'Please fill up your information due to the authorization manners.',
    __unavailableSubscription: 'Please purchase the membership to continue.',
    __unknown: 'Please try another reference music link or video. Due to YouTube\'s license policy, we can\'t analyze this YouTube link as reference music in the area where A.V. Mapping\'s server based.',
    __validated: 'The email needs to be validated.'
  },
  lackOfMusic: {
    __wrongType: 'The compensate type is missing or not acceptable.',
    __alreadySent: 'The compensate ticket is already sent.',
    __overLimit: 'The compensate ticket is reach monthly limit.'
  },
  dashboard: {
    button: {
      __platform: 'Hit the shelf on other platform',
      __preference: 'Test your preference',
      __work: ['Share your work', 'Increase your profit'],
      __referral: ['Share your referral code'],
      __upload: ['Get profit by uploading your soundtracks']
    }
  },
  library: {
    nav: {
      __project: 'In Progress',
      __music: 'Music Library',
      __finished: 'Completed Project'
    },
    music: {
      __project: 'Save & Likes',
      __projectName: 'Project name',
      __soundtrackName: 'Soundtrack name',
      __purchased: 'Purchased soundtrack',
      __uploaded: 'Uploaded soundtrack',
      __uploadButton: 'Upload soundtrack →'
    },
    finished: {
      __test: 'Test your preference →',
      __create: 'Create your storyboard →',
      __browse: 'Browse others'
    }
  },
  music: {
    __like: 'Only show liked music, turn ♥ light on.',
    __title: 'Music Library',
    budget: {
      __min: 'Minimum  ≧ 1',
      __max: 'Maximum ≦ 70M'
    },
    buttons: {
      __cancel: 'Clear',
      __confirm: 'Confirm',
      __start: 'Start free mapping'
    },
    license: {
      __selectPlaceholder: 'Unselected',
      __type: 'Type',
      area: {
        __country: 'Authorized Country',
        __region_type: 'Authorized Region Type',
        __region: 'Authorized Region',
        __title: 'Authorized Area'
      },
      time: {
        __title: 'Authorized Time'
      },
      usage: {
        __commercial: 'Commercial',
        __noncommercial: 'Non Commercial',
        __platform: 'Platform',
        __title: 'Usage'
      }
    },
    options: {
      __artist: 'Artist',
      __bpm: 'BPM',
      __budget: 'Budget',
      __currency: '(USD)',
      __genre: 'Genre',
      __instrument: 'Instrument',
      __keyword: 'Keyword',
      __lang: 'Language',
      __emotion: 'Emotion',
      __outline: 'Plot Summary',
      __ref: 'Reference Music',
      __time: 'Insert Time'
    }
  },

  profile: {
    __contractIdentity: 'Identity',

    options: {
      __affiliation: 'Affiliation Program',
      __audience: 'Audience',
      __brand: 'Brand',
      __media: 'Media, News',
      __musicians: 'Musicians',
      __sponsors: 'Sponsors',
      __superStars: 'Super Stars',
      __technology: 'Technology Company',
      __visualCreators: 'Visual Creators (Filmmakers, Animator, Artists, Youtubers etc.)',
      __web3: 'Web 3.0 Project'
    }
  },

  sign: {
    up: {
      __title: 'Create Your Account',
      __free: 'Start 14-day Free Trial',
      __email: 'Email address',
      __pwd: 'Password',
      __repwd: 'Confirmed password',
      __btn: 'Register',
      __link: 'I am already a member',
      __alert: 'Please check your email and click the magic link inside.',
      __alert_2: 'You have successfully registered.',
      __verified: 'Account Verified, we are going to explore dashboard.',
      __google: 'Log up with Google',
      __facebook: 'Continue with Facebook'
    },

    in: {
      __title: 'Sign in to A.V. Mapping',
      __email: 'Email address',
      __pwd: 'Password',
      __forgetPwd: 'Forgot Password?',
      __btn: 'Sign In',
      __linkText1: 'New here? ',
      __linkText2: 'Sign up for FREE, activate premium 14 days trial.',
      __alert1: 'The column should not be empty.',
      __alert2: 'Password or email incorrect, please re-enter.',
      __alert3: 'Your email is not verified yet, please click the link in the mail from "info@avmapping.co" in your mailbox.',
      __alert4: 'Please Signin.',
      __google: 'Log in with Google',
      __facebook: 'Continue with Facebook'
    },

    forget: {
      __title: 'Reset Password',
      __email: 'Email address',
      __btn: 'Submit',
      __alert: 'Reset Password: Enter your email address and we will send you a reset password link.'
    },

    error: {
      __alert: 'Please disable the block for third-party login pop-ups. If the issue persists, please use incognito mode to prevent interference from related extension application settings.'
    }
  },

  wish: {
    __cancel: 'Clear',
    __confirm: 'Confirm',
    __success: 'Upload successful',

    __tip: `
      Please set goal specifically. Set up the numbers concretely. Ex.<br />
      ❌ " I want money" ⭕ "To have $1M in two month by initiate 'Signals' "<br />
      ⭕ "Gain 10K followers in a month" "Lose 10 Pounds/3 KG in a month" 。
    `,

    file: {
      __detail: 'Details',
      __upload: 'Upload an Image'
    },

    link: {
      __cancel: 'Cancel',
      __complete: 'Complete',
      __fail: 'Please enter google docs, spreadsheets, YouTube link',

      placeholder: {
        __wish: 'Reference file. Accept editing file as .docx, .txt, .csv, .xlsv, .html, .md, .pptx<br />Or .pdf, .jpg, .png, but take the risk of missing information.',
        __share: 'Specifications, catalog, flyers, reference information, brochure.<br />Accept editing file as .docx, .txt, .csv, .xlsv, .html, .md, .pptx<br />Or .pdf, .jpg, .png, but take the risk of missing information.',
        __link: 'Online link in public: google docs, spreadsheets, YouTube link'
      }
    },

    placeholder: {
      __1: 'I am looking for...',
      __2: 'Title',
      __3: 'Wishes',
      __4: 'I am looking for...',
      __5: 'Title',
      __6: 'Description',
      __drag: 'Or drag an image here'
    }
  },

  // landing page
  signals: {
    button: {
      __1: 'Go, Signals',
      __2: 'Freemium',
      __2_1: 'Simplified Functions',
      __3: 'Premium',
      __3_1: '14 Days Free Trial'
    },

    match: {
      __sign: 'Match',
      __title: ' your dream comes true',
      __subtitle: 'Maximize time efficiency with AI matching and automations between people, business, products and service.',
      __fail: 'Something went wrong, please try again.',

      share: {
        __1: 'Share Your Service',
        __2: 'products, solutions, events, or story.',
        __3: 'Signals finds your matches.',

        __button: 'Start Sharing',
        __submit: 'SHARE'
      },

      wish: {
        __1: 'Make A Wish',
        __2: 'Signals make<br />dream comes true',

        __button: 'I Wish ...',
        __submit: 'SEND'
      },

      letter: {
        __placeholder: 'Leave your email here',
        __button: 'Start!',
        __success: 'Just subscribed',
        __error: 'Please enter correct email address.'
      }
    },

    mock: {
      content: {
        __signals: 'Signals, make dream comes true Finding the fastest solutions and strategy in business and personal life. The magic of soul recognition and creative fusion.',
        __mapping: 'One-Stop Audio-Video synchronization & licensing Platform. Find musicians-made music for film, videos with patented AI matching in seconds.<br />Stand with creators.'
      },

      sign: {
        one: {
          __1: '💰 Revenue Growth in 4⁴ EXP',
          __2: '🤠 Hire 20K Experts',
          __3: '📍 Shorten 303K KM',
          __4: '🌍 Elevate Energy 🔆',
          __5: '🫰🏻 Changing Inequality Gap ↔️',
          __6: '🫱🏻‍🫲🏼 Making Common wealth 🧑‍🤝‍🧑',
          __7: '🌳 Save 20 Trees from Carbon Reduction'
        },

        two: {
          __1: '💰 Saved the Loss of $ 8.29B USD',
          __2: '🤠 Saved 693 Years From Creators',
          __3: '🌳 Helped 190K Trees',
          __4: '❤ 25K Creators',
          __5: '📍 From 126 Countries',
          __6: '🎛️ 100K Music Licensed'
        }
      }
    }
  },

  canva: {
    __success: 'Please paste the order ID into Canva’s window.',
    __fail: 'It didn’t go well, please try again.'
  }
}
