/* eslint-disable */
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import api from '../api'
import i18n from '../plugins/i18n'
import zipy from 'zipyai'
import { parse } from '@/plugins/qs'
Vue.use(VueRouter)

// 確認是否有權限
const authority = window.sessionStorage.getItem('authority') === null ? 'false' : window.sessionStorage.getItem('authority')
const routes = [
  {
    path: '/',
    redirect: '/user/userInfo',
    name: 'main',
    component: () => import('@/views/main.vue'),
  },
  {
    path: '/dynamicAnalysis',
    name: 'dynamicAnalysis',
    meta: {
      noMobile: false,
    },
    redirect: '/dynamicAnalysis/step1',
    component: () => import('@/views/dynamicAnalysis/index.vue'),
    children: [
      {
        path: '/dynamicAnalysis/step1',
        name: 'step1',
        meta: {
          noMobile: false,
        },
        component: () => import('@/views/dynamicAnalysis/step1-v2.vue'),
      },
      {
        path: '/dynamicAnalysis/step1/:id',
        name: 'step1-reselect',
        meta: {
          noMobile: false,
        },
        component: () => import('@/views/dynamicAnalysis/step1-reselect.vue'),
      },
      {
        path: '/dynamicAnalysis/step1-2',
        name: 'step1-2',
        meta: {
          noMobile: false,
        },
        component: () => import('@/views/dynamicAnalysis/step1-2-v2.vue'),
      },
      {
        path: '/dynamicAnalysis/adjust',
        name: 'adjust',
        component: () => import('@/views/dynamicAnalysis/adjust.vue'),
      },
      {
        path: '/dynamicAnalysis/step2',
        name: 'step2',
        component: () => import('@/views/dynamicAnalysis/step2-v2.vue'),
      },
      {
        path: '/dynamicAnalysis/checkout',
        name: 'checkout',
        props: true,
        component: () => import('@/views/dynamicAnalysis/checkout.vue'),
      },
      {
        path: '/dynamicAnalysis/download',
        name: 'download',
        component: () => import('@/views/dynamicAnalysis/download.vue'),
      },
      {
        path: '/makeOrder',
        name: 'makeOrder',
        component: () => import('@/views/makeOrder.vue'),
      },
      /**
       * 錄影分析
       */
      {
        path: '/dynamicAnalysis/recorder',
        name: 'recorder',
        component: () => import('@/views/dynamicAnalysis/recorder.vue')
      },
      /**
       * 快速分析頁
       */
      {
        path: '/dynamicAnalysis/search',
        name: 'step1-search',
        component: () => import('@/views/dynamicAnalysis/step1-search.vue'),
      },
      // 活動頁 start
      {
        path: '/dynamicAnalysis/step2-village',
        name: 'step2-village',
        component: () => import('@/views/dynamicAnalysis/step2-village.vue'),
      },
      {
        path: '/dynamicAnalysis/wish-village',
        name: 'wish-village',
        component: () => import('@/views/dynamicAnalysis/wish-village.vue'),
      },
      // 活動頁 end
    ],
  },
  {
    path: '/audition',
    name: 'audition',
    redirect: '/',
    component: () => import('@/views/audition/index.vue'),
    children: [
      {
        path: '/audition/:code',
        name: 'auditionPage',
        component: () => import('@/views/audition/page.vue')
      },
      {
        path: '/audition/music-library/:code',
        name: 'auditionMusicPage',
        component: () => import('@/views/audition/musicPage.vue')
      }
    ]
  },
  {
    path: '/user',
    name: 'user',
    redirect: '/user/userInfo',
    component: () => import('@/views/user/index.vue'),
    children: [
      {
        path: '/user/userInfo',
        name: 'userInfo',
        components: {
          default: () => import('@/views/user/userInfo'),
          nav: () => import('@/views/user/navigation')
        }
      },
      {
        path: '/user/mySong',
        name: 'songList',
        components: {
          default: () => import('@/views/user/mySong'),
          nav: () => import('@/views/user/navigation')
        }
      },
      {
        path: '/user/uploadContract',
        name: 'uploadContract',
        components: {
          default: () => import('@/views/user/uploadContract'),
          nav: () => import('@/views/user/navigation')
        }
      },
      {
        path: '/user/uploadSong',
        name: 'uploadSong',
        components: {
          default: () => import('@/views/user/uploadSong'),
          nav: () => import('@/views/user/navigation')
        }
      },
      {
        path: '/user/films',
        name: 'films',
        components: {
          default: () => import('@/views/user/films/index'),
          nav: () => import('@/views/user/navigation')
        },
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/user/films/:id',
        name: 'films-show',
        components: {
          default: () => import('@/views/user/films/show'),
          nav: () => import('@/views/user/navigation')
        }
      },
      {
        path: '/user/myWallet',
        name: 'myWallet',
        components: {
          default: () => import('@/views/user/myWallet'),
          nav: () => import('@/views/user/navigation')
        }
      },
      {
        path: '/user/tradeDetails',
        name: 'tradeDetails',
        components: {
          default: () => import('@/views/user/tradeDetails'),
          nav: () => import('@/views/user/navigation')
        }
      },
      {
        path: '/user/tx',
        name: 'tx',
        component: () => import('@/views/user/tx/index.vue'),
      },
      {
        path: '/user/tx/orders',
        name: 'tx-orders',
        component: () => import('@/views/user/tx/orders/index.vue'),
      },
      {
        path: '/user/dashboard',
        name: 'dashboard',
        // 未開放頁面設定判斷，有權限才能觀看
        components: {
          default: () => {
            if (authority === 'true') {
              return import('@/views/user/dashboard/index')
            } else {
              return import('@/views/user/underConstruction')
            }
          },
          nav: () => import('@/views/user/dashboard/sidebar')
        },
      },
      // library
      {
        path: '/library/project',
        name: 'project',
        components: {
          default: () => {
            if (authority === 'true') {
              return import('@/views/user/library/project')
            } else {
              return import('@/views/user/underConstruction')
            }
          },
          nav: () => import('@/views/user/library/sidebar')
        },
      },
      {
        path: '/library/music',
        name: 'music',
        components: {
          default: () => {
            if (authority === 'true') {
              return import('@/views/user/library/music')
            } else {
              return import('@/views/user/underConstruction')
            }
          },
          nav: () => import('@/views/user/library/sidebar')
        },
      },
      {
        path: '/library/finished',
        name: 'finished',
        components: {
          default: () => {
            if (authority === 'true') {
              return import('@/views/user/library/finished')
            } else {
              return import('@/views/user/underConstruction')
            }
          },
          nav: () => import('@/views/user/library/sidebar')
        },
      }
    ],
  },
  {
    path: '/music',
    redirect: '/music-library',
    component: () => import('@/views/music/index.vue'),
    children: [
      {
        path: '/music-library',
        name: 'music-library',
        components: {
          default: () => import('@/views/music/soundtrack.vue'),
          nav: () => import('@/views/music/sidebar.vue')
        }
      },
    ]
  },
  {
    path: '/signals',
    name: 'signals',
    component: () => import('@/views/signals/index.vue')
  },
  {
    path: '/wish',
    name: 'wish',
    component: () => import('@/views/wish/index.vue')
  }
]
const router = new VueRouter({
  routes,
})

/**
 * Error: Avoided redundant navigation to current location
 */
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const $isDev = process.env.NODE_ENV === 'development'

/** 關閉loading動畫 */
const stopLoading = () =>{
  store.commit('stopLoadingCircular')
  store.commit('startLoadingCircular', {})
}

/** 本地模式 使用測試帳號直接登入url 無須帶token */
const devMode = async () =>{
  console.log('開發環境 登入😀')

  // 開啟權限
  window.sessionStorage.setItem('authority', true)

  if(store.state.token) return;
  const email = process.env.VUE_APP_TEST_ACCOUNT
  const password = process.env.VUE_APP_TEST_PASSWORD
  await api.login.login({ email,password })
    .then(({ status,data:{ key } }) => {
      if (status === 200) {
        store.dispatch('setToken', key)
      }})
    .catch(e => console.err(e));
}

// 解析 URL 參數
const parseUrlParams = () => {
  return parse(window.location.href.split('?')[1])
}

// 設定語系
const checkLocale = (lang) => {
  if (window.localStorage.getItem('lang') === null) {
    window.localStorage.setItem('lang', lang === 'zh' ? 'zh-tw' : 'en')
    i18n.locale = lang === 'zh' ? 'zh-tw' : 'en'
    return
  }

  if (lang !== undefined) {
    window.localStorage.setItem('lang', lang === 'zh' ? 'zh-tw' : 'en')
    i18n.locale = lang === 'zh' ? 'zh-tw' : 'en'
  }
}

/** 確認網址有無token */
const checkUrlToken = async () =>{
  const urlQs = parseUrlParams()
  if (urlQs.token) {
    // TODO 將官網的lang 改為zh-tw , vue內lang存store
    if (urlQs.lang === 'zh') {
      // 重新設定語系
      i18n.locale = 'zh-tw'
      window.localStorage.setItem('lang', 'zh-tw')
    }else{
      // 重新設定語系
      i18n.locale = 'en'
      window.localStorage.setItem('lang', 'en')
    }

    await store.dispatch('setToken', urlQs.token)
    return true
  }
}

// 設定身份
const checkUrlIdentity = () => {
  const urlQs = parseUrlParams()
  const identityMap = {
    photographer: 'photographer',
    musician: 'musician'
  }

  if (urlQs.identity) {
    const identity = identityMap[urlQs.identity] || 'musician'
    store.commit('user/setUserIdentity', identity)
    window.sessionStorage.setItem('identity', identity)
  }
}

/** 確認網址參數 */
const checkUrlQuery = async () => {
  const urlQs = parseUrlParams()
  const { id, page, session, subscribe, token } = urlQs

  if (token) {
    await store.dispatch('setToken', token)
  }

  // 確認是否有 subscribe, page, sync 相關參數
  if (id || page || session || subscribe || token) {
    return true
  }
  return false
}

/** 確認網址是不是同時有 id 和 session id（sync 頁面） */
const checkSyncParams = () => {
  const urlQs = parseUrlParams()
  const keys = Object.keys(urlQs)
  return keys.includes('id') && keys.includes('session') && urlQs.page === 'sync'
}

/**
 * 判斷是不是devMode  -> 走自動登入
 * 確認網址是否有token
 *                    有 ->  setToken -> url 把token移除
 *                    無 ->  不做動作
 * 確認store有無token
 *                    有 -> 讓他去要去的頁面
 *                    無 -> 跳出尚未登入彈窗
 * 確認是否為手機且去手機沒做好的頁面
 *                    有 -> 挑出手機上為支援彈窗
 *                    無 -> 讓他去要去的頁面
 */
router.beforeEach(async (to, from, next) => {
  const isDynamicAnalysis = to.path.includes('/dynamicAnalysis/') || to.path.includes('/wish') || to.path.includes('/signals')
  const isAudition = to.path.includes('/audition')
  const isMobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  const urlHasQuery = await checkUrlQuery()
  const urlQs = parseUrlParams()

  /**
   * 目前 Analyzer 頁面的 Header 底色與其他畫面不一樣
   * 用來判斷是否在 Analyzer 頁面
   */
  isDynamicAnalysis || isAudition ? store.commit('isBlueMode', true) : store.commit('isBlueMode', false)

  checkUrlIdentity()
  checkLocale(urlQs.lang)

  if($isDev){
    await devMode();
    stopLoading()
    return next()
  }
  // } else {
  //   if (urlQs.token || store.state.token) {
  //     zipy.identify(urlQs.token)
  //   }
  // }

  // 判斷網址是否有特定參數，有的話跳過 router 檢查，到特定頁面後另外處理
  if(urlHasQuery) {
    return next()
  } else if (to.name === 'signals') {
    return next()
  }

  if (urlQs.token) {
    await store.dispatch('setToken', urlQs.token)
    window.location.href = window.location.href.split('?')[0]
    return next(to.path)
  }

  if (store.state.token){
    next();
  } else if (isAudition) {
    // 試聽頁面就跳過確認 token 的檢查
    next()
  }else{
    store.commit('callAlert', {
      content: i18n.t('system.__login'),
      confirmCallback: function () {
        store.commit('toggleSignView', {
          page: 'login',
          path: to.path,
          state: true
        })
      },
    })
  }

  // 禁止移動裝置觀看
  if (isMobile && to.meta.noMobile) {
    store.commit('callAlert', {
      content: i18n.t('system.__disableMobile')
    })
    return next(from.path)
  }
  store.commit('setNowPage', to.path)
})

router.afterEach(() => {
  store.commit('stopLoadingCircular')
})

export default router
