import axios from './axios.js'

const baseUrl = `${process.env.VUE_APP_API_ONES_URL}`
const makeFormdata = (data) => {
  const passData = new FormData()
  Object.keys(data).forEach((key) => {
    if (Array.isArray(data[key])) {
      data[key].forEach((item) => {
        passData.append(`${key}`, item)
      })
    } else {
      passData.append(key, data[key])
    }
  })
  return passData
}

export default {
  fetchOption (key) {
    return axios({
      method: 'GET',
      url: `${baseUrl}/${key}/option`,
      headers: {
        'x-api-key': `${process.env.VUE_APP_API_ONES_KEY}`
      }
    })
  },

  postOption (key, params) {
    const data = makeFormdata(params)
    return axios({
      method: 'POST',
      url: `${baseUrl}/${key}/`,
      data,
      headers: {
        'content-type': 'multipart/form-data',
        'x-api-key': `${process.env.VUE_APP_API_ONES_KEY}`
      }
    })
  }
}
