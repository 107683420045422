import axios from './axios.js'

const baseUrl = `${process.env.VUE_APP_API_BASE_URL}/letter/newsletters/`

export default {
  subscribeNewsletter (data) {
    return axios({
      method: 'POST',
      url: `${baseUrl}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data
    })
  }
}
