<template lang="pug">
v-app
  tool-bar
  router-view(v-if='isRouterAlive' class='main')
  alert
  sign
  analyzer-share(:code='share.code' :status='share.status' :share-type='share.type' @update:status='share.status = false')
  loading-circular
  transition(name='lackOfMusic')
    lack-of-music(v-if='$store.state.lackOfMusic.status')
</template>

<script>
import toolBar from '@/components/toolBar'
import alert from '@/components/alert'
import loadingCircular from '@/components/loadingCircular'
import LackOfMusic from '@/components/LackOfMusic'
import Sign from '@/components/Sign/SignIndex.vue'
import AnalyzerShare from '@/components/AnalyzerShare.vue'
export default {
  name: 'app',
  components: {
    toolBar,
    alert,
    loadingCircular,
    LackOfMusic,
    Sign,
    AnalyzerShare
  },
  provide () {
    return {
      provideCurrency: this.currency,
      providePrice: this.priceConvert,
      provideShare: this.fetchShareCode,
      reload: this.reload,
      toggleShareState: this.toggleShareState
    }
  },
  data: () => ({
    alert: {
      open: false,
      text: ''
    },

    share: {
      code: '',
      type: '',
      status: false
    },

    isRouterAlive: true
  }),
  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    },

    /**
     * 貨幣:
     * 如果使用者有自己選擇幣別，就用使用者選擇的幣別
     * 否則依照語系給予對應的幣別：中文 / TWD, 英文 / USD
     */
    currency () {
      // 使用者沒有選擇的話會是空值('')
      const storeCurrency = this.$store.state.currency

      if (storeCurrency !== '') {
        return storeCurrency
      } else {
        return this.$i18n.locale === 'en' ? 'USD' : 'TWD'
      }
    },
    /**
     * 金額: 設定小數點，加入千分位分隔符號
     */
    priceConvert (price, point = 0) {
      const float = price.toFixed(point)
      const parts = float.toString().split('.')
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      return parts.join('.')
    },

    /**
     * 取得分享連結
     * @param { String } service
     * @param { * } film_id
     * @param { * } music_id
     */
    async fetchShareCode (service, film_id, music_id) {
      const datas = {}

      switch (service) {
        case 'soundtrack':
          datas['musics'] = [music_id]
          break
        case 'effect':
          datas['sound_effects'] = [music_id]
          break
        case 'reduction':
          datas['noise_reduce_audios'] = [music_id]
          break
      }

      await this.$api.film
        .fetchAuditionURL(film_id, datas)
        .then(response => {
          this.toggleShareState(response.data.share_code)
        })
    },

    toggleShareState (share_code, share_type = '') {
      this.share.code = share_code
      this.share.type = share_type
      this.share.status = true
    }
  }
}
</script>
<style lang="less" scoped>
@import './styles/less/defaut.less';
#app {
  font-family: 'Nunito', 'Noto Sans TC', 'PingFangTC',  sans-serif;
  background-color: #181818;
  color: #fefefe;
  max-width: 100%;
  min-height: 100vh;
  position: relative;
  overflow-y: auto;
  .scrollStyle;
}
.main {
  position: relative;
  margin: 80px auto 0;
  width: 1440px;
  height: calc(100vh - 80px);
}
@media (max-width: 1440px) {
  .main {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .main {
    margin: 50px auto 0;
  }
}

.lackOfMusic {
  &-enter-active,
  &-leave-active {
    transition: opacity 0.3s ease-in-out;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
  }
}
</style>
<style lang="less">
html {
  overflow-y: auto;
}
</style>
